import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/jenkins/agent/workspace/vOps_provision-documentation_dev/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const InlineNotification = makeShortcode("InlineNotification");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Summary`}</h2>
    <p>{`This document is an update to the BD Pyxis ES System release notes.`}</p>
    <p>{`To announce the release of BD Pyxis Enterprise Server (ES) System 1.7.4 Update 4 fix.`}</p>
    <br />
    <h2>{`Purpose`}</h2>
    <p>{`To announce the release of BD Pyxis Enterprise Server (ES) System 1.7.4 Update 4 fix.`}</p>
    <h2>{`Background`}</h2>
    <p>{`This release addresses the following bugs:`}</p>
    <p>{`Bug ID: 1310032
Title: 1.7.4 - database bloat for MedStations with purge deletion errors when the exception is not handled properly.
Symptom:
Database size continues to increase without decreasing. At the device side databases were close to hitting the 10GB limit.
In normal operation the database size should fluctuate due to the addition and deletion of data.
In addition, the maintenance log records errors when deleting data.
Resolution:
Updated the maintenance code so during the delete process, the offending record is inserted in the purge queue error table and deleted from the purge queue table.
This allows maintenance service to delete the next item in the purge queue table.`}</p>
    <p>{`Bug ID: 1281431
Title: User is not valid - IDS reads the wrong AD lockout attribute.
Symptom:
Users are locked out of the Active Directory (AD) due to consecutive failed logins or customer’s inactivity policy against the AD.
Users are automatically allowed access (unlocked) when the AD account lockout duration policy has expired.
However, in the ES System environment, users are still not allowed access to the system.
Resolution:
Updated Pyxis IDS code to derive locked status from the property recommended by Microsoft instead of Account Lockout Time.
This will allow IDS to correctly identify whether the user account is locked.`}</p>
    <h2>{`Affected Population`}</h2>
    <p>{`Customers using BD Pyxis™ ES System v1.7.4.
This release is for ES Server only, maintenance fix does not apply to devices. Devices may exhibit this bloat issue.
To address the device bloat issue, refer to KA# 000016830.`}</p>
    <h2>{`Resolution (For technical issues only)`}</h2>
    <p>{`As a result of this release, the following software versions are available for deployment:`}</p>
    <ul>
      <li parentName="ul">{`es-system-releases-server 1.7.4.136 containing the below new builds:`}
        <pre parentName="li"><code parentName="pre" {...{}}>{` - pyxis-dispensing-maintenance-server-service: 1.0.2.20
 - pyxis-identity-server: 4.0.1.49
`}</code></pre>
        {`Refer to KA# 00016830 for detailed installation instructions.`}</li>
    </ul>
    <h2>{`Action required (as needed)`}</h2>
    <p>{`Responsible Team: N/A
Detailed instructions of action required: N/A`}</p>
    <h2>{`Training Requirements (above basic training for that product)`}</h2>
    <p>{`N/A.`}</p>
    <h2>{`Tools`}</h2>
    <p>{`N/A.`}</p>
    <h2>{`Part Numbers`}</h2>
    <p>{`N/A.`}</p>
    <h2>{`FAQ`}</h2>
    <ol>
      <li parentName="ol">{`How long does this update take to install or deploy?
This hotfix takes about 10 to 20 minutes to complete from start to finish depending on server configuration and network speed.`}</li>
      <li parentName="ol">{`Does this hotfix require downtime?
Yes, Maintenance and Identity Server services will be stopped during the duration of the in-place upgrade process.
With Identity Server service stopped, users will not be able to log into the ES System unless disconnected mode is enabled.`}</li>
      <li parentName="ol">{`Does this update require a reboot or a database backup?
No, just a restart of the affected services (maintenance and identity server services).`}</li>
      <li parentName="ol">{`How can a user tell if the hotfix was applied?
BD support use can display the version of the ES Server system release by typing the following at a command prompt:
`}<inlineCode parentName="li">{` choco list -l`}</inlineCode>{`
ES Server system release version should be 1.7.4.136`}</li>
      <li parentName="ol">{`Are device full syncs required?
No.`}</li>
      <li parentName="ol">{`Is there additional work required post upgrade of the server?
Yes. Ensure both Identity Server and Maintenance Services are running after the upgrade.`}</li>
    </ol>
    <InlineNotification kind="warning" mdxType="InlineNotification">
      <p><strong parentName="p">{`Warning:`}</strong>{`
This document is confidential and proprietary. You are obligated to maintain the confidentiality of this information.
Do not disclose or distribute any of the BD product documents and/or information contained in this or other communications.
For additional questions or concerns, we welcome you to call the BD Technical Support Center at 800.625.6535.
For international contact information, please visit `}<a parentName="p" {...{
          "href": "http://www.bd.com"
        }}>{`www.bd.com`}</a>{`.`}</p>
    </InlineNotification>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      